import React from 'react'
import Page from '../../../../../ui/Page'
import useFetchData from '../../../../../hooks/useFetchData'
import { AcademicMobilityCompetitionService } from '../../../../../services/AcademicMobilityCompetitionService'
import { AcademicMobilityCompetitionDtoResponse, AcademicMobilityCompetitionStatusDto, StudentAcademicMobilityCompetitionDto } from '../../../../../interfaces/AcademicMobilityCompetitionDto'
import { Button, Descriptions, List, Select, Typography } from 'antd'
import { getFullDate } from '../../../../../common/getFullDate'
import RowSpan from '../../../../../ui/RowSpan'
import BackButton from '../../../../../ui/BackButton'
import MobilityStatusTag from '../MobilityStatusTag'
import { InfoCircleOutlined } from '@ant-design/icons'

interface Props {
    id: number
}

const AcademicMobilityCompetitionDetailPage = ({ id }: Props) => {
    const [statusId, setStatusId] = React.useState<number>(1);
    const callbackCompetition = React.useCallback(() => AcademicMobilityCompetitionService.getCompetitionById(id), [id]);
    const callbackStudentCompetitions = React.useCallback(() => AcademicMobilityCompetitionService.getStudentCompetitionsByCompetitionIdAndStatus(id, statusId), [id, statusId]);

    const { data: competition } = useFetchData<AcademicMobilityCompetitionDtoResponse>(callbackCompetition, true);
    const { data: studentStatuses } = useFetchData<AcademicMobilityCompetitionStatusDto[]>(AcademicMobilityCompetitionService.getStudentCompetitionStatuses, true);
    const { data: studentCompetitions, isLoading: studentLoading } = useFetchData<StudentAcademicMobilityCompetitionDto[]>(callbackStudentCompetitions, true);

    return (
        <Page title='Competition details' subtitle={competition ? String(competition.university.nameEn) : 'No competition data'}>
            <RowSpan
                left={<BackButton />}
            />
            <Descriptions title="General info" style={{ marginTop: 20 }}>
                <Descriptions.Item label="Status">
                    {competition?.status ? <MobilityStatusTag status={competition?.status} /> : 'N/A'}
                </Descriptions.Item>
                <Descriptions.Item label="Created date">{getFullDate(competition?.createdAt?.toString() || '')}</Descriptions.Item>
                <Descriptions.Item label="Study period">{
                    `${getFullDate(competition?.studyStartAt?.toString() || '')} / ${getFullDate(competition?.studyEndAt?.toString() || '')}`
                }</Descriptions.Item>
                <Descriptions.Item label="Quota">{competition?.quota}</Descriptions.Item>
                <Descriptions.Item label="Financing source">{competition?.financingSource.nameEn}</Descriptions.Item>
                <Descriptions.Item label="Study terms">{
                    competition?.studyStartTerm &&
                    competition?.studyEndTerm &&
                    `From term ${competition?.studyStartTerm} until term ${competition?.studyEndTerm}`}
                </Descriptions.Item>
            </Descriptions>

            <List
                header={
                    <>
                        <Select placeholder={"Select status"} defaultValue={statusId} onSelect={e => { setStatusId(e) }} style={{ minWidth: "200px" }}>
                            {studentStatuses?.sort((a, b) => a.id - b.id).map((status) => (
                                <Select.Option key={status.id} value={status.id}>
                                    {status.nameEn}
                                </Select.Option>
                            ))}
                        </Select>
                        <p>Количество студентов: {studentCompetitions?.length}</p>
                    </>
                }
                itemLayout="horizontal"
                bordered
                dataSource={studentCompetitions}
                loading={studentLoading}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: false,
                }}
                renderItem={(item) => (
                    <List.Item
                        key={item.id}
                        style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}
                        actions={[
                            <Button icon={<InfoCircleOutlined />} href={`/academic-mobility/competition/student/${item.id}`} target={"_blank"}>
                                View
                            </Button>
                        ]}
                    >
                        <List.Item.Meta
                            title={<Typography.Text>{`${item.student.fullNameEn} - (${item.student.group.title})`}</Typography.Text>}
                            description={<MobilityStatusTag status={item.status} />}
                        />
                    </List.Item>
                )}
            />
        </Page>
    )
}

export default AcademicMobilityCompetitionDetailPage