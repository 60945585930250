import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UserPage from '../../pages/UserPage';
import AcademicDept from '../../pages/Academic Department';
import AuthenticatedContent from '../../../common/AuthenticatedContent';
import CoursePage from '../../pages/CoursePage';
import TranscriptPage from '../../pages/TranscriptPage';
import AcademicDepartmentTranscript from '../../pages/AcademicDepartmentTranscript';
import DisciplinesCatalogPage from '../../pages/DisciplinesCatalogPage';
import DisciplinesCreationPage from '../../pages/DisciplinesCreationPage/DisciplinesCreationPage';
import EducationalProgramsCatalogPage from '../../pages/EducationalProgramsCatalogPage';
import EducationalProgramsCreationPage from '../../pages/EducationalProgramsCreationPage';
import CurriculumCreationPage from '../../pages/CurriculumCreationPage';
import AllocatedHoursCreationPage from '../../pages/AllocatedHoursCreationPage';
import { StudentDisciplineEnrollment } from '../../pages/Student Discipline Enrollment';
import StudentDisciplinesPage from '../../pages/StudentDisciplinesPage';
import DiplomaSupplementPage from '../../pages/DiplomaSupplementPage';
import FinalAttestationProtocolPage from '../../pages/FinalAttestationProtocolPage';
import StudentProfilePage from '../../pages/StudentProfilePage';
import Disease from '../../pages/Disease';
import Certificate from '../../pages/CertificatesPage/Certificate';
import CertificateList from '../../pages/CertificatesPage/CertificateList';
import ApproveCurriculumPage from '../../pages/ApproveCurriculumPage';
import ConfirmStudentInfoPage from '../../pages/ConfirmStudentInfoPage';
import StudentDebtPage from '../../pages/StudentDebtPage';
import OrdersPage from '../../pages/OrdersPage';
import EditOrderPage from '../../pages/OrdersPage/EditOrderPage';
import CreateOrderPage from '../../pages/OrdersPage/CreateOrderPage';
import TeacherProfile from '../../pages/TeacherProfile/TeacherProfile';
import ProfilePage from '../../pages/ProfilePage';
import StudentsDetailsPage from '../../pages/StudentsDetailsPage';
import StudentCurriculumDetailPage from '../../pages/ApproveCurriculumPage/StudentCurriculumDetailPage';
import TeachersPage from '../../pages/TeachersPage/TeachersPage';
import TeacherCreationPage from '../../pages/TeachersPage/TeacherCreationPage/TeacherCreationPage';
import StudentStatisticsPage from '../../pages/StudentStatisticsPage';
import TeacherStatementPage from '../../pages/TeacherStatementPage';
import DeclinedPage from '../../pages/Academic Department/DeclinedPage';
import AcademicRatingPage from '../../pages/AcademicRatingPage';
import AppointmentList from '../../pages/AppointmentList';
import AddAppointmentPage from '../../pages/AppointmentList/AddAppointmentPage';
import PsychologicalAppointmentPage from '../../pages/PsychologistAppointmentPage';
import CertificateRegistration from '../../pages/CertificateRegistration';
import StudentIrosPage from '../../pages/StudentIrosPage';
import ApplicationsList from '../../pages/StudentIrosPage/ApplicationsList';
import IrosIndicators from '../../pages/IrosIndicators';
import CreateIrosIndicators from '../../pages/IrosIndicators/CreateIrosIndicators';
import IrosPointCreationPage from '../../pages/IrosIndicators/IrosPointCreationPage';
import SsciEventPage from '../../pages/SsciPage/Events';
import SsciCreateEventPage from '../../pages/SsciPage/Events/Create';
import SsciAddStudentPage from '../../pages/SsciPage/Events/Students';
import IrosTeachersPage from '../../pages/IrosTeachersPage';
import SsciStudentEventPointPage from '../../pages/SsciPage/Events/Students/Points';
import TeacherPage from '../../pages/TeacherPage/TeacherPage';
import SurveyPage from '../../pages/SurveyPage';
import KpiApplicationPage from '../../pages/KpiPage';
import KpiApprovePage from '../../pages/KpiPage/HR';
import KpiSettingsPage from '../../pages/KpiPage/HR/Settings';
import SsciEditStudentPage from '../../pages/SsciPage/Events/Students/Edit';
import StudentSSCIPage from '../../pages/SsciPage/StudentSSCIPage/StudentSSCIPage';
import AdminSurveyPage from '../../pages/SurveyPage/Admin';
import AdminSurveyDetailsPage from '../../pages/SurveyPage/Admin/Survey';
import StudentSurveyListPage from '../../pages/SurveyPage/Student';
import StudentSurveyDetails from '../../pages/SurveyPage/Student/Survey';
import SsciStudentApplications from '../../pages/SsciPage/Applications';
import StudentsAttendance from '../../pages/StudentAttendance/StudentsAttendance';
import ElectiveDisciplinesPage from '../../pages/CurriculumCreationPage/ElectiveDisciplinesPage';
import RequisitePage from '../../pages/CurriculumCreationPage/ElectiveDisciplinesPage/RequisitePages/RequisitePage';
import { StudentApplicationPage, StudentApplicationsListPage } from '../../pages/ApplicationPage';
import ApplicationDetailPage from '../../pages/ApplicationPage/ApplicationDetailPage';
import CreateStudentApplicationPage from '../../pages/ApplicationPage/Student/CreateStudentApplicationPage';
import { SyllabusDetailPage, SyllabusDraftPage, SyllabusGroupsAndStreamsCreatePage, SyllabusGroupsAndStreamsEditPage, SyllabusGroupsAndStreamsPage, SyllabusPage, SyllabusStudyStreamsPage } from '../../pages/SyllabusPage';
// import StudentsBenefitsPage from '../../pages/StudentBenefitsPage';
// import StudentsRetiresPage from '../../pages/StudentsRetiresPage';
// import ProfControlPage from '../../pages/ProfControl/ProfControlPage';
import FxExamApplication from '../../pages/FxExamApplication/FxExamApplication';
import ServicesPage from '../../pages/ServicesPage';
import FxExamApplicationsList from '../../pages/FxExamApplication/FxExamApplicationsList';
import ApprovedFxExamApplications from '../../pages/FxExamApplication/ApprovedFxExamApplicatons';
import FxExamPageForTeachers from '../../pages/FxExamApplication/FxExamPageForTeachers';
import EditStudentInfo from '../../pages/EditStudentInfoPage/EditStudentInfoPage';
import ForeignUniversitiesPage from '../../pages/ForeignUniversitiesPage'
import ForeignUniversitiesCreationPage from '../../pages/ForeignUniversitiesCreationPage';
import AcademicMobilityCreationPage from '../../pages/AcademicMobility/AcademicMobilityCreationPage';
import AcademicMobilityPage from '../../pages/AcademicMobility/AcademicMobilityPage';
import StudentPersonalSsciPage from '../../pages/StudentPersonalSsciPage';
import StudentPersonalIrosPage from '../../pages/StudentPersonalIrosPage';
import StudyDiscount from '../../pages/StudyDIscount';
import CurriculumEditPage from '../../pages/CurriculumEditPage';
import ExpectedLearningOutcomesCreationPage from '../../pages/ExpectedLearningOutcomesCreationPage';
import ExpectedLearningOutcomesCatalogPage from '../../pages/ExpectedLearningOutcomesCatalogPage';
import { TeacherOrderPage } from '../../pages/TeacherOrderPage';
import DeanOfficeAppointment from '../../pages/DeansAppointment';
import DeanOfficeAppointmentList from '../../pages/DeansAppointment/DeanOfficeAppointmentList';
import SummerRetakeDisciplines from '../../pages/SummerRetakeApplicationPage/SummerRetakeDisciplines';
import SummerRetakeApplications from '../../pages/SummerRetakeApplicationPage/SummerRetakeApplications';
import SummerRetakeApplicationsList from '../../pages/SummerRetakeApplicationPage/SummerRetakeApplicationsList';
import { ReportsListPage } from '../../pages/ReportsPage';
import AgreementPage from '../../pages/AgreementPage';
import AgreementTypePage from '../../pages/AgreementTypePage';
import AgreementCreationPage from '../../pages/AgreementPage/AgreementCreationPage';
import AgreementTypeCreationPage from '../../pages/AgreementTypePage/AgreementTypeCreationPage';
import DiplomaWorksCatalogPage from '../../pages/DiplomaWorksCatalogPage';
import StudentGrades from '../../pages/StudentGrades';
import CreateNotificationPage from '../../pages/NotificationPage/CreateNotificationPage';
import NotificationPage from '../../pages/NotificationPage';
import NotificationByUserPage from '../../pages/NotificationPage/NotificationByUserPage';
import DormApplicationsListPage from '../../pages/ApplicationPage/Office/DormApplicationsListPage';
import PhysicalEducationCourseEnrollmentPage from '../../pages/PhysicalEducationCourseEnrollmentPage/PhysicalEducationCourseEnrollmentPage';
import PhysicalEducationSettings from '../../pages/PhysicalEducationCourseEnrollmentPage/PhysicalEducationSettings';
import PhysicalEducationTypes from '../../pages/PhysicalEducationCourseEnrollmentPage/PhysicalEducationTypes';
import { AcademicMobilityCompetitionDetailPage, AcademicMobilityCompetitionPage, StudentAcademicMobilityCompetitionDetailPage, StudentMobilityCompetitionsPage } from '../../pages/AcademicMobility/AcademicMobilityCompetitionPage';
const PageContent = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={UserPage}></Route>
        <Route exact path="/teachers" component={TeachersPage}></Route>
        <Route exact path="/teacher/:id" render={({ match }) => <TeacherPage isPublic={false} />} />
      </Switch>

      <AuthenticatedContent role={'OFFICE'} type={'baseRole'}>
        {/* <Route path={'/student-retires'} component={StudentsRetiresPage} />
        <Route path={'/student-benefits'} component={StudentsBenefitsPage} /> */}
        <Route path={'/syllabus'} exact component={SyllabusPage} />
        <Route path={'/syllabus-draft/:id'} render={({ match }) => <SyllabusDraftPage id={parseInt(match.params.id)} />} />
        <Route path={'/syllabus/:id'} render={({ match }) => <SyllabusDetailPage id={parseInt(match.params.id)} />} />
        <Route path={'/syllabus/groups-and-streams'} exact component={SyllabusGroupsAndStreamsPage} />
        <Route path={'/syllabus/groups-and-streams/create'} component={SyllabusGroupsAndStreamsCreatePage} />
        <Route path={'/syllabus/groups-and-streams/edit/:streamId'} render={({ match }) => (
          <SyllabusGroupsAndStreamsEditPage id={parseInt(match.params.streamId)} />
        )} />

        <Route path={'/syllabus/study-streams'} component={SyllabusStudyStreamsPage} />

        <Route path={'/students-iros'} component={ApplicationsList} />
        <Route path={'/iros-indicators'} component={IrosIndicators} />
        <Route path={'/create-iros-indicators'} component={CreateIrosIndicators} />
        {/* <Route path={'/prof-control'} component={ProfControlPage} /> */}
        <Route
          path="/indicators/edit/:id"
          render={({ match }) => <CreateIrosIndicators indicatorId={parseInt(match.params.id)} />}
        />
        <Route
          path="/iros-indicator/indicator-id/:indicatorId/:indicatorTitle"
          render={({ match }) => (
            <IrosPointCreationPage
              indicatorId={parseInt(match.params.indicatorId)}
              indicatorTitle={match.params.indicatorTitle}
            />
          )}
        />
        <Route path='/applications'
          render={({ match }) => <StudentApplicationsListPage isAllApplications={true} />} />
        <Route path={'/applications-for-sign'}
          render={({ match }) => <StudentApplicationsListPage isSignApplicationsPage={true} />} />
        <AuthenticatedContent role={'STUDENT_DEPARTMENT'} type={'addRole'}>
          <Route path={'/applications-for-order'}
            render={({ match }) => <StudentApplicationsListPage isOrderFormVisible={true} />} />
        </AuthenticatedContent>
        <AuthenticatedContent role={'DSI_DEPARTMENT'} type={'addRole'}>
          <Route path={'/applications-for-dorm'}
            render={({ match }) => <DormApplicationsListPage />} />
        </AuthenticatedContent>

        <Route path={'/application/:id'} render={({ match }) => <ApplicationDetailPage id={parseInt(match.params.id)} />} />
        <Route path={'/notification-create'} exact component={CreateNotificationPage} />
        <Route path={'/notification'} exact component={NotificationPage} />

        <Route path={"/academic-mobility/competition/:id"} exact render={({ match }) => <AcademicMobilityCompetitionDetailPage id={parseInt(match.params.id)} />} />
        <Route path={"/academic-mobility/competition/student/:id"} render={({ match }) => <StudentAcademicMobilityCompetitionDetailPage id={parseInt(match.params.id)} />} />

        <AuthenticatedContent role={'SEW_DEPARTMENT'} type={'addRole'}>
          <Switch>
            <Route path={'/ssci-events'} exact component={SsciEventPage} />
            <Route path={'/ssci-applications'} exact component={SsciStudentApplications} />
            <Route path={'/create-ssci-event'} component={SsciCreateEventPage} />
            <Route
              path={'/edit-ssci-event/:id'}
              render={({ match }) => <SsciCreateEventPage eventId={parseInt(match.params.id)} />}
            />
            <Route
              path={'/add-ssci-students/:id'}
              render={({ match }) => <SsciAddStudentPage eventId={parseInt(match.params.id)} />}
            />
            <Route
              path={'/edit-ssci-students/:id'}
              render={({ match }) => <SsciEditStudentPage id={parseInt(match.params.id)} />}
            />
            <Route
              path={'/ssci-events/point/:id'}
              render={({ match }) => <SsciStudentEventPointPage eventId={parseInt(match.params.id)} />}
            />
          </Switch>
        </AuthenticatedContent>
        <Switch>
          {/* <Route path="/academic" component={EducationalDepartment}></Route> */}
          <Route path="/aсademic_transcript" component={AcademicDepartmentTranscript}></Route>
          <Route path={'/academic_rating'} component={AcademicRatingPage}></Route>
          <Route path={'/students-attendance'} component={StudentsAttendance}></Route>
          <Route path={'/students-grades'} component={StudentGrades}></Route>
          <Route path={'/study-discount'} component={StudyDiscount}></Route>
          {/* <Route path={'/academic-stream'} exact component={AcademicStreamPage} /> */}
          {/* <Route path="/expected-learning-outcomes" exact component={ExpectedLearningOutcomesCatalogPage} />
          <Route
            path="/expected-learning-outcomes/create/education-program-id/:educationalProgramId"
            render={({ match }) => (
              <ExpectedLearningOutcomesCreationPage
                educationalProgramId={parseInt(match.params.educationalProgramId)}
              />
            )}
          />
          <Route
            path="/expected-learning-outcomes/edit/:expectedLearningOutcomeId/education-program-id/:educationalProgramId"
            render={({ match }) => (
              <ExpectedLearningOutcomesCreationPage
                expectedLearningOutcomeId={parseInt(match.params.expectedLearningOutcomeId)}
                educationalProgramId={parseInt(match.params.educationalProgramId)}
              />
            )}
          />
          {/*<Route path="/esuvo-disciplines" component={EsuvoDisciplines} />
          <Route path="/esuvo-programs" component={EsuvoEducationalPrograms} />
          <Route path="/esuvo-curriculums" component={EsuvoCurriculums} />
          <Route
            path="/esuvo-curriculum/:id"
            render={({ match }) => <ViewCurriculum id={parseInt(match.params.id)} />}
          />
          <Route path="/esuvo-curriculums" component={EsuvoCurriculums} />
          <Route path="/students-ep-choice" component={StudentsEducationalPrograms} />
          <Route path="/bachelor-contingent" component={BachelorContingent} /> */}
          <Route path="/student-debt" component={StudentDebtPage} />
          <Route path="/students-details" component={StudentsDetailsPage} />
          {/* <Route path="/create-student" component={StudentCreatePage} /> */}
          <Route path="/profile/:id" render={({ match }) => <ProfilePage userId={parseInt(match.params.id)} />} />
          <Route path="/statistics" component={StudentStatisticsPage} />
          {/* <Route path="/add-esuvo-discipline" component={AddEsuvoDiscipline} /> */}
        </Switch>

        <AuthenticatedContent role={'DEAN'} type={'addRole'}>
          <Switch>
            {/* <Route path="/disease-list" component={Disease} /> */}
            <Route path="/certificate-list" component={CertificateList} />
            <Route path="/dean-office-appointment-list" component={DeanOfficeAppointmentList} />
          </Switch>
        </AuthenticatedContent>
        <Route path="/educational-programs" exact component={EducationalProgramsCatalogPage} />

        <AuthenticatedContent role={'IR_DEPARTMENT'} type={'addRole'}>
          <Route path="/foreign-universities" exact component={ForeignUniversitiesPage} />
          <Route path="/foreign-universities/create" component={ForeignUniversitiesCreationPage} />
          <Route
            path="/foreign-universities/edit/:id"
            render={({ match }) => (
              <ForeignUniversitiesCreationPage universityId={parseInt(match.params.id)} />
            )}
          />
          <Route path="/academic-mobility" exact component={AcademicMobilityPage} />
          <Route path="/academic-mobility/competitions" component={AcademicMobilityCompetitionPage} />
          <Route path="/academic-mobility/create" component={AcademicMobilityCreationPage} />
          <Route
            path="/academic-mobility/edit/:id"
            render={({ match }) => (
              <AcademicMobilityCreationPage academicMobilityId={parseInt(match.params.id)} />
            )}
          />
          <Route path="/agreement" exact component={AgreementPage} />
          <Route path="/agreement/create" component={AgreementCreationPage} />
          <Route
            path="/agreement/edit/:id"
            render={({ match }) => (
              <AgreementCreationPage agreementId={parseInt(match.params.id)} />
            )}
          />
          <Route path="/agreement-type" exact component={AgreementTypePage} />
          <Route path="/agreement-type/create" component={AgreementTypeCreationPage} />
          <Route
            path="/agreement-type/edit/:id"
            render={({ match }) => (
              <AgreementTypeCreationPage typeId={parseInt(match.params.id)} />
            )}
          />
        </AuthenticatedContent>

        <AuthenticatedContent role={'ACCOUNTANT'} type={'addRole'}>
          <Switch>
            <Route exact path="/fx-exam-applications-list" component={FxExamApplicationsList} />
            <Route exact path="/retake-applications-list" component={SummerRetakeApplicationsList} />
          </Switch>
        </AuthenticatedContent>


        <AuthenticatedContent role={'OFFICE_REGISTRAR'} type={'addRole'}>
          <Switch>
            <Route path="/vedomost" exact component={AcademicDept}></Route>
            <Route path="/vedomost/status" component={DeclinedPage}></Route>
            <Route path="/physical-education-course-seetings" component={PhysicalEducationSettings}></Route>
            <Route path="/physical-education-course-types" component={PhysicalEducationTypes}></Route>
            <Route exact path="/approved-fx-exam-applications" component={ApprovedFxExamApplications} />
            <Route exact path="/summer-retake-applications" component={SummerRetakeApplications} />
            <Route exact path="/students-curriculum" component={ApproveCurriculumPage} />
            <Route
              path="/students-curriculum/detail/:id"
              render={({ match }) => <StudentCurriculumDetailPage studentId={parseInt(match.params.id)} />}
            />
            <Route exact path="/surveys" component={AdminSurveyPage} />
            <Route
              path={'/survey/:id'}
              render={({ match }) => <AdminSurveyDetailsPage id={parseInt(match.params.id)} />}
            />
          </Switch>
        </AuthenticatedContent>

        <AuthenticatedContent role={['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT', 'STUDENT_DEPARTMENT', 'TECHNICAL_SECRETARY']} type={'addRole'}>
          <Route path="/reports" exact component={ReportsListPage}></Route>
        </AuthenticatedContent>

        <AuthenticatedContent role={['OFFICE_REGISTRAR', 'ACADEMIC_DEPARTMENT']} type={'addRole'}>
          <Switch>
            <Route path="/disciplines" exact component={DisciplinesCatalogPage} />
            <Route path="/disciplines/create" component={DisciplinesCreationPage} />
            <Route
              path="/disciplines/edit/:id"
              render={({ match }) => <DisciplinesCreationPage disciplineId={parseInt(match.params.id)} />}
            />
            <Route path="/student-personal-ssci-application" exact component={StudentPersonalSsciPage} />
            <Route path="/student-personal-iros-application" exact component={StudentPersonalIrosPage} />
            <Route
              path="/allocated-hours/discipline-id/:disciplineId"
              render={({ match }) => <AllocatedHoursCreationPage disciplineId={parseInt(match.params.disciplineId)} />}
            />
            <Route path="/educational-programs/create" component={EducationalProgramsCreationPage} />
            <Route
              path="/educational-programs/edit/:id"
              render={({ match }) => (
                <EducationalProgramsCreationPage educationalProgramId={parseInt(match.params.id)} />
              )}
            />
            <Route
              path="/curriculum/education-program-id/:educationalProgramId"
              exact
              render={({ match }) => (
                <CurriculumCreationPage educationalProgramId={parseInt(match.params.educationalProgramId)} />
              )}
            />
            <Route
              path="/curriculum/edit/:id"
              render={({ match }) => <CurriculumEditPage curriculumId={parseInt(match.params.id)} />}
            />
            <Route
              path="/elective/education-program-id/:educationalProgramId"
              exact
              render={({ match }) => (
                <ElectiveDisciplinesPage educationalProgramId={parseInt(match.params.educationalProgramId)} />
              )}
            />
            <Route
              path="/elective/education-program-id/:educationalProgramId/curriculum/:curriculumId/:requisiteType"
              render={({ match }) => (
                <RequisitePage
                  educationalProgramId={parseInt(match.params.educationalProgramId)}
                  curriculumId={parseInt(match.params.curriculumId)}
                  requisiteType={match.params.requisiteType as 'postreq' | 'prereq'}
                  handleSuccess={() => { }}
                />
              )}
            />
            <Route path="/diploma-supplement" component={DiplomaSupplementPage} />
          </Switch>
        </AuthenticatedContent>
        <AuthenticatedContent role={'DIPLOMA_CATALOG'} type={'addRole'}>
          <Route path="/catalog-diploma" component={DiplomaWorksCatalogPage} />
        </AuthenticatedContent>
        <AuthenticatedContent role={'TECHNICAL_SECRETARY'} type={'addRole'}>
          <Route path="/final-attestation-protocol" component={FinalAttestationProtocolPage} />
        </AuthenticatedContent>

        <AuthenticatedContent role={'CCE_DEPARTMENT'} type={'addRole'}>
          <Switch>
            <Route exact path="/certificate-registration" component={CertificateRegistration}></Route>
          </Switch>
        </AuthenticatedContent>

        <AuthenticatedContent role={'STUDENT_DEPARTMENT'} type={'addRole'}>
          <Switch>
            <Route exact path="/order-list" component={OrdersPage} />
            <Route exact path="/order-list/create" component={CreateOrderPage} />
            <Route
              exact
              path="/order-list/edit/:orderId"
              render={({ match }) => <EditOrderPage orderId={parseInt(match.params.orderId)} />}
            />
            <Route exact path="/edit-student-info/:userId" component={EditStudentInfo} />
          </Switch>
        </AuthenticatedContent>
      </AuthenticatedContent>
      {/* <AttributeBasedContent requestedAtt={["OFFICE"]}>
        <Switch>
          <Route path="/vedomost" component={AcademicDept}></Route>
        </Switch>
      </AttributeBasedContent> */}
      <AuthenticatedContent role={'TEACHERS'} type={'baseRole'}>
        <Switch>
          <Route exact path="/profile" component={TeacherProfile}></Route>
          <Route exact path="/teacher/course/:disciplineId" component={CoursePage}></Route>
          <Route exact path={'/vedomost'} component={TeacherStatementPage} />
          <Route exact path={'/iros'} component={IrosTeachersPage} />
          <Route exact path={'/kpi'} component={KpiApplicationPage} />
          <Route exact path={'/fx-exam-students'} component={FxExamPageForTeachers} />
          {/*Academ dep component*/}
          {/* <Route exact path='/grades' component={TranscriptPage}></Route> */}
        </Switch>
      </AuthenticatedContent>
      <AuthenticatedContent role={'PSYCHOLOGIST'} type={'addRole'}>
        <Switch>
          <Route path="/appointment-list" component={AppointmentList} />
          <Route path="/add-appointment-page" component={AddAppointmentPage} />
        </Switch>
      </AuthenticatedContent>
      <AuthenticatedContent role={'STUDENTS'} type={'baseRole'}>
        <Switch>
          <Route path="/disease" component={Disease} />
          <Route path="/transcript" component={TranscriptPage} />
          <Route path="/course-enrollment" component={StudentDisciplineEnrollment} />
          <Route path="/disciplines" component={StudentDisciplinesPage} />
          {/* <Route path="/student-profile" component={StudentProfilePage} /> */}
          <Route path="/certificate" component={Certificate} />
          <Route path="/approve-curriculum" component={ApproveCurriculumPage} />
          <Route path="/personal-information" component={ConfirmStudentInfoPage} />
          {/* <Route path="/student-debt" component={StudentDebtPage} /> */}
          <Route path="/profile" component={ProfilePage} />
          {/* <Route path="/services" component={ServicesPage} /> */}
          <Route path="/psychologist-appointment-page" component={PsychologicalAppointmentPage} />
          <Route exact path="/iros-page" component={StudentIrosPage} />
          <Route exact path="/ssci-page" component={StudentSSCIPage} />
          <Route exact path="/fx-exam-application" component={FxExamApplication} />
          <Route exact path="/physical-education-course-enrollment" component={PhysicalEducationCourseEnrollmentPage} />
          <Route exact path="/retake-application" component={SummerRetakeDisciplines} />

          <Route exact path={'/survey'} component={SurveyPage} />
          <Route path={'/surveys'} component={StudentSurveyListPage} />
          <Route path={'/survey/:id'} render={({ match }) => <StudentSurveyDetails id={parseInt(match.params.id)} />} />
          <Route path={'/applications'} exact component={StudentApplicationPage} />
          <Route path={'/application/create'} component={CreateStudentApplicationPage} />
          <Route path={'/application/:id'} render={({ match }) => <ApplicationDetailPage id={parseInt(match.params.id)} />} />
          <Route path={'/dean_office_appointment'} component={DeanOfficeAppointment} />
          <Route path={'/all-notifications'} component={NotificationByUserPage} />
          <Route path={"/academic-mobility/competitions"} component={AcademicMobilityCompetitionPage} />
          <Route path={"/academic-mobility/assigned"} component={StudentMobilityCompetitionsPage} />
          <Route path={"/academic-mobility/competition/:id"} render={({ match }) => <StudentAcademicMobilityCompetitionDetailPage id={parseInt(match.params.id)} />} />
        </Switch>
      </AuthenticatedContent>
      <AuthenticatedContent role={'HR'} type={'addRole'}>
        <Switch>
          {/* <Route exact path="/teachers" component={TeachersPage}></Route>
          <Route exact path="/teacher/:id" render={({ match }) => <TeacherPage />} /> */}
          <Route path="/create-teacher" component={TeacherCreationPage} />
          <Route exact path="/kpi" component={KpiApprovePage} />
          <Route path="/kpi/settings" component={KpiSettingsPage} />
          <Route path={"/orders"} component={TeacherOrderPage} />
        </Switch>
      </AuthenticatedContent>
    </React.Fragment>
  );
};

export default PageContent;
