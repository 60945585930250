import authAxios from '../common/authAxios';
import { AbiturCatalogDtoResponse } from '../interfaces/AbiturCatalogDtoResponse';
import { StudentDtoRequest } from '../interfaces/Student/StudentDtoRequest';
import { StudentDtoResponse } from '../interfaces/Student/StudentDtoResponse';
import { StudentStatusTypeDtoResponse } from '../interfaces/Student/StudentStatusTypeDtoResponse';
import { StudentStudyingStatusTypeDtoResponse } from '../interfaces/Student/StudentStudyingStatusTypeDtoResponse';
import { FinancingDtoResponse } from '../interfaces/FinancingDtoResponse';
import { CountryDtoResponse } from '../interfaces/Student/CountryDtoResponse';
import { StudentFamilyDtoResponse } from '../interfaces/Student/StudentFamilyDtoResponse';
import axios from 'axios';
import { CatalogDtoResponse } from '../interfaces/CatalogDtoResponse';
import { DictionaryCatalogDtoResponse } from '../interfaces/DictionaryCatalogDtoResponse';

export class StudentService {
  private static endpointPrefix: string = '/astanait-student-module/api/v1/student';

  static async getStudentIdByUserId(userId: number) {
    return await authAxios.get(`${this.endpointPrefix}/get-student-by-user-id?user_id=${userId}`);
  }

  static async getStudentsByPrincipalWithEducationProgram() {
    return await authAxios.get(`${this.endpointPrefix}/get-student-by-principal-with-education-program`)
      .then((response) => response.data)
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  static async getStudentFamilyByStudentId(studentId: number) {
    return await authAxios.get<StudentFamilyDtoResponse[]>(`${this.endpointPrefix}/student-family?student_id=${studentId}`);
  }

  static async updateStudentFamily(studentFamilyDtoRequest: any, id: number) {
    return await authAxios.put(`${this.endpointPrefix}/update-student-family?id=${id}`, studentFamilyDtoRequest);
  }

  static async createStudentFamily(studentFamilyDtoRequest: any) {
    return await authAxios.post(`${this.endpointPrefix}/create-student-family`, studentFamilyDtoRequest);
  }

  static async deleteStudentFamily(id: number) {
    return await authAxios.delete(`${this.endpointPrefix}/delete-student-family?id=${id}`);
  }

  static async create(student: StudentDtoRequest) {
    return await authAxios.post(`${this.endpointPrefix}/create`, student);
  }

  static getNationalityList() {
    return authAxios.get(`/astanait-student-module/api/v1/nationality/get-all`);
  }

  static getCitizenshipList() {
    return authAxios.get(`/astanait-student-module/api/v1/citizenship/get-all`);
  }

  static async updateStudentInfo(userId: number, values: any) {
    return await authAxios.put(`/astanait-student-module/api/v1/student/update-student?user_id=${userId}`, values);
  }

  static getFinancingList() {
    return authAxios.get<FinancingDtoResponse[]>(`/astanait-student-module/api/v1/student/financing`);
  }

  static getStudentStatusList() {
    return authAxios.get<StudentStatusTypeDtoResponse[]>(`/astanait-student-module/api/v1/student/status/types`);
  }

  static getStudentSocialStatusList() {
    return authAxios.get<AbiturCatalogDtoResponse[]>(`/astanait-student-module/api/v1/student/status/social/types`);
  }

  static getStudentStudyStatusList() {
    return authAxios.get<StudentStudyingStatusTypeDtoResponse[]>(`/astanait-student-module/api/v1/student/study-status/types`);
  }

  static getCountries() {
    return axios.get<CountryDtoResponse[]>(`${process.env.REACT_APP_API_BASE_URL}/astanait-student-module/api/v1/public/countries`);
  }

  static getStudentsByFilter(params: any) {
    for (const key of Object.keys(params)) {
      if (!params[key]) {
        delete params[key];
      }
    }

    return authAxios.get<StudentDtoResponse[]>(`${this.endpointPrefix}/get-student-by-filter`, { params });
  }

  static getAwardTypes() {
    return authAxios.get<DictionaryCatalogDtoResponse[]>(`/astanait-student-module/api/v1/dict/award-types`);
  }
}
