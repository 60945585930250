import { Button, DatePicker, Form, FormInstance, Input, Modal, Select } from 'antd';
import React from 'react'
import { TeacherDtoResponse } from '../../../interfaces/Teacher/TeacherDtoResponse';
import { TeacherDtoRequest } from '../../../interfaces/Teacher/TeacherDtoRequest';
import useDictionary from '../../../hooks/useDictionary';
import { TeacherService } from '../../../services/TeacherService';
import { successNotification } from '../../../helpers/successNotification';
import InputMask from "react-input-mask";
import { useTheme } from '../../../themes/useTheme';
import { EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';


interface TeacherEditModalProps {
    form?: FormInstance;
    teacherInfo?: TeacherDtoResponse;
    fetchTeacherData?: () => void;
}

const TeacherEditModal = ({
    form,
    teacherInfo,
    fetchTeacherData,
}: TeacherEditModalProps) => {

    const { dictionary } = useDictionary({ fetchNationailty: true, fetchCitizenship: true });
    const [open, setOpen] = React.useState(false);
    const [isDarkMode] = useTheme();

    const onUpdate = (teacherData: TeacherDtoRequest) => {
        // const additionalInfo = Object.entries(teacherData.additionalInfo)
        //     .map(([type, value]) => {
        //         return {
        //             type,
        //             value
        //         }
        //     });

        // teacherData.additionalInfo = additionalInfo.map(({ type, value }) => ({ type, value: value.toString() }));
        TeacherService.updateTeacherInfo(teacherData)
            .finally(() => {
                fetchTeacherData && fetchTeacherData();
                setOpen(false);
                successNotification("Profile information updated!")
            })
    }

    return (
        <>
            <Button
                type="primary"
                icon={<EditOutlined />}
                disabled={teacherInfo?.status.id === 2}
                onClick={() => setOpen(true)}
            >
                Edit
            </Button>
            <Modal
                open={open}
                title="Edit teacher"
                okText="Edit"
                cancelText="Cancel"
                okButtonProps={{ autoFocus: true, htmlType: 'submit' }}
                onCancel={() => setOpen(false)}
                destroyOnClose
                modalRender={(dom) => (
                    <Form
                        layout="vertical"
                        form={form}
                        name="form_in_modal"
                        onFinish={(values) => onUpdate(values)}
                    >
                        {dom}
                    </Form>
                )}
            >
                <Form.Item name={"nameKz"} label={"First name"}>
                    <Input
                        defaultValue={teacherInfo?.nameKz}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"surnameKz"} label={"Last name"}>
                    <Input
                        defaultValue={teacherInfo?.surnameKz}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"patronymicKz"} label={"Patronymic"}>
                    <Input
                        defaultValue={teacherInfo?.patronymicKz}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"nameEn"} label={"First name (English)"}>
                    <Input
                        defaultValue={teacherInfo?.nameEn}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"surnameEn"} label={"Last name (English)"}>
                    <Input
                        defaultValue={teacherInfo?.surnameEn}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item name={"iin"} label={"IIN"}>
                    <Input
                        defaultValue={teacherInfo?.iin}
                        maxLength={12}
                        size={"middle"}
                    />
                </Form.Item>
                <Form.Item
                    label="Birth Date"
                    name="birthDate"
                    style={{ marginBottom: '10px', width: '100%' }}
                    getValueFromEvent={(onChange) => moment(onChange).format('YYYY-MM-DD')}
                    getValueProps={(i) => ({ value: i ? moment(i) : null })}
                >
                    <DatePicker placeholder="Select birth date" style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item name={"phone"} label={"Phone"}>
                    <InputMask
                        mask={"+7 (999) 999-99-99"}
                        defaultValue={teacherInfo?.phone}
                        style={isDarkMode ? {
                            backgroundColor: "#1f1f1f",
                            width: "100%",
                            padding: "6.5px 11px 6.5px",
                            border: "1px solid #434343"
                        } : {
                            width: "100%",
                            padding: "6.5px 11px 6.5px",
                            border: "1px solid #434343"
                        }}
                    >
                    </InputMask>
                </Form.Item>
                <Form.Item
                    name={"nationalityId"}
                    label={"Nationality"}
                >
                    <Select placeholder="Select option" defaultValue={teacherInfo?.nationality?.id} size={"middle"} allowClear style={{ width: "100%", borderRadius: "10px" }}>
                        {dictionary.nationalities?.map((option: any) => (
                            <Select.Option key={option.id} value={option.id}>
                                {option.titleEn}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name={"citizenshipId"}
                    label={"Citizenship"}
                >
                    <Select placeholder="Select option" defaultValue={teacherInfo?.citizenship?.id} size={"middle"} allowClear style={{ width: "100%", borderRadius: "10px" }}>
                        {dictionary.citizenships?.map((option: any) => (
                            <Select.Option key={option.id} value={option.id}>
                                {option.titleEn}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Gender"
                    name="sexId"
                >
                    <Select placeholder="Select option" defaultValue={teacherInfo?.sexId} allowClear size={"middle"} style={{ width: "100%", borderRadius: "10px" }}>
                        {dictionary.genderListEn.map((option) => (
                            <Select.Option key={option.id} value={option.id}>
                                {`${option.value}`}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.List
                    name="scientificInterests"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    label={index === 0 ? 'Scientific interests' : ''}
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input scientific interest name or delete this field.",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input placeholder="Scientific interest" style={{ width: "93%" }} />
                                    </Form.Item>
                                    <Button
                                        icon={<MinusCircleOutlined />}
                                        onClick={() => remove(field.name)}
                                    />
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '100%' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Scientific Interest
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.List
                    name="taughtCourses"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    label={index === 0 ? 'Taught courses' : ''}
                                    required={false}
                                    key={field.key}
                                >
                                    <Form.Item
                                        {...field}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                            {
                                                required: true,
                                                whitespace: true,
                                                message: "Please input taught course name or delete this field.",
                                            },
                                        ]}
                                        noStyle
                                    >
                                        <Input placeholder="Taught course" style={{ width: "93%" }} />
                                    </Form.Item>
                                    <Button
                                        icon={<MinusCircleOutlined />}
                                        onClick={() => remove(field.name)}
                                    />
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '100%' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add Taught Course
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>

            </Modal >
        </>
    )
}

export default TeacherEditModal