import UniversalTable from '../UniversalTeachersTable';
import useFetchData from '../../../../hooks/useFetchData';
import { TeacherTrainingDirectionDtoRequest, TeacherTrainingDirectionDtoResponse } from '../../../../interfaces/Teacher/EPVO/TeacherEpvoDto';
import { TeacherService } from '../../../../services/TeacherService';
import { successNotification } from '../../../../helpers/successNotification';

interface Props {
    dataSource?: TeacherTrainingDirectionDtoResponse[];
}

const TeacherTrainingTable = ({ dataSource }: Props) => {
    const { data: trainings, fetchData: fetchTrainings } = useFetchData<TeacherTrainingDirectionDtoResponse[]>(TeacherService.getTeacherTrainingDirections, dataSource && dataSource?.length > 0 ? false : true);

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
        }
    ];

    const addRecord = (record: TeacherTrainingDirectionDtoRequest) => {
        TeacherService.createOrUpdateTeacherTrainingDirection(record)
            .then(() => {
                fetchTrainings();
            })
            .finally(() => {
                successNotification('Training added!')
            })
    };

    const editRecord = (record: TeacherTrainingDirectionDtoRequest) => {
        TeacherService.createOrUpdateTeacherTrainingDirection(record)
            .then(() => {
                fetchTrainings();
            })
            .finally(() => {
                successNotification('Training updated!')
            })
    };

    const deleteRecord = (record: TeacherTrainingDirectionDtoRequest) => {
        TeacherService.deleteTeacherTrainingDirection(record.id)
            .then(() => {
                fetchTrainings();
            })
            .finally(() => {
                successNotification('Training deleted!')
            })
    };

    return (
        <UniversalTable
            columns={columns}
            dataSource={trainings ? trainings.map((training, i) => ({
                id: training.id,
                key: i + 1,
                name: training.centerTrainingDirection.nameEn + "/" + training.centerTrainingDirection.nameRu + "/" + training.centerTrainingDirection.nameKz,
                number: training.centerTrainingDirection.codeDirection,
            })) : []}
            addRecord={(e: TeacherTrainingDirectionDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherTrainingDirectionDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherTrainingDirectionDtoRequest) => deleteRecord(e)}
            hideActions={true}
            hideCreate={true}
            isPublic={false}
        />
    )
}

export default TeacherTrainingTable