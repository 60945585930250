import UniversalTable from '../UniversalTeachersTable';
import { TeacherService } from '../../../../services/TeacherService';
import { successNotification } from '../../../../helpers/successNotification';
import { TeacherICInfoDtoRequest, TeacherICInfoDtoResponse } from '../../../../interfaces/Teacher/EPVO/TeacherEpvoDto';
import useFetchData from '../../../../hooks/useFetchData';

const TeacherICTable = () => {
    const { data: ic, fetchData: fetchIC } = useFetchData<TeacherICInfoDtoResponse[]>(TeacherService.getTeacherICInfo, true);

    const columns = [
        {
            title: '#',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Number',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Issue date',
            dataIndex: 'issueDate',
            key: 'issueDate',
        },
        {
            title: 'Expiration date',
            dataIndex: 'expirationDate',
            key: 'expirationDate',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Issuing authority',
            dataIndex: 'issuingAuthority',
            key: 'issuingAuthority',
        }
    ];

    const addRecord = (record: TeacherICInfoDtoRequest) => {
        TeacherService.createOrUpdateTeacherICInfo(record)
            .then(() => {
                fetchIC();
            })
            .finally(() => {
                successNotification('Training added!')
            })
    };

    const editRecord = (record: TeacherICInfoDtoRequest) => {
        TeacherService.createOrUpdateTeacherICInfo(record)
            .then(() => {
                fetchIC();
            })
            .finally(() => {
                successNotification('Training updated!')
            })
    };

    const deleteRecord = (record: TeacherICInfoDtoRequest) => {
        TeacherService.deleteTeacherICInfo(record.teacherId)
            .then(() => {
                fetchIC();
            })
            .finally(() => {
                successNotification('Training deleted!')
            })
    };

    return (
        <UniversalTable
            columns={columns}
            dataSource={ic ? ic.map((ic, i) => ({
                id: ic.teacher.id,
                key: i + 1,
                number: ic.number,
                issueDate: ic.issueDate,
                expirationDate: ic.validityPeriod,
                type: ic.icType?.name,
                issuingAuthority: ic.icDepartment?.name
            })) : []}
            addRecord={(e: TeacherICInfoDtoRequest) => addRecord(e)}
            editRecord={(e: TeacherICInfoDtoRequest) => editRecord(e)}
            deleteRecord={(e: TeacherICInfoDtoRequest) => deleteRecord(e)}
            hideActions={true}
            hideCreate={true}
            isPublic={false}
        />
    )
}

export default TeacherICTable