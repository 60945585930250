import { Link, useParams } from 'react-router-dom';
import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  notification,
  Progress,
  Row,
  Select,
  Space,
  Spin,
  Tabs,
  Tag,
  Collapse,
} from 'antd';
import { useEffect, useState } from 'react';
import { TeacherService } from '../../../services/TeacherService';
import Spinner from '../../../ui/Spinner';
import { DownloadOutlined, LeftOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import PageWrapper from '../../../ui/PageWrapper';
import FormItem from 'antd/lib/form/FormItem';
import useHR from '../TeachersPage/useHR';
import { TeacherDtoResponse } from '../../../interfaces/Teacher/TeacherDtoResponse';
import UserService from '../../../services/userService';
import { UserDtoResponse } from '../../../interfaces/UserDtoResponse';
import grantPermission from '../../../helpers/grantPermission';
import AuthenticatedContent from '../../../common/AuthenticatedContent';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import PublicTeacherArticlesTable from '../TeacherArticlesTable/PublicTable';
import PublicTeacherCertificatesTable from '../TeacherCertificatesTable/PublicTable';
import PublicTeacherEducationTable from '../TeacherEducationTable/PublicTable';
import PublicTeacherScientificProjectsTable from '../TeacherScientificProjectsTable/PublicTable';
import { TeacherPublicTeacherDetailDtoResponse } from '../../../interfaces/Teacher/TeacherPublicTeacherDetailDtoResponse';
import PublicTeacherScholarInformationTable from '../TeacherScholarInformation/PublicTable';
import PublicTeacherWorkExpTable from '../TeacherWorkExpTable/PublicTable';
import { useTheme } from '../../../themes/useTheme';
import InputMask from 'react-input-mask';
import Text from 'antd/lib/typography/Text';
import PublicTeacherDevelopmentTable from '../TeacherDevelopmentsTable/PublicTable';
import PublicTeacherAcademicTitleTable from '../TeacherAcademicTitleTable/Public';
import PublicTeacherLegalDocsTable from '../TeacherLegalDocsTable/Public';
import { KpiService } from '../../../services/kpi/KpiService';
import { KpiApplicationSettingsDtoResponse } from '../../../interfaces/Kpi/KpiApplicationSettingsDtoResponse';
import { getFullDate } from '../../../common/getFullDate';
import { TeacherKpiApplicationWithKPIDto } from '../../../interfaces/Kpi/TeacherKpiApplicationWithKPIDto';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';
import ScheduleForTeacherPage from '../StudentSchedulePage/ScheduleForTeacherPage';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import useDictionary from '../../../hooks/useDictionary';
import { TeacherAdditionalInfoDtoRequest } from '../../../interfaces/Teacher/TeacherAdditionalInfoDtoRequest';
import { TeacherICTable, TeacherOrderTable, TeacherTrainingTable } from '../TeachersPage/TeacherTables';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement);

interface Props {
  isPublic: boolean;
}

const TeacherPage = ({ isPublic }: Props) => {

  // @ts-ignore
  const { id } = useParams();
  const [teacherInfo, setTeacherInfo] = useState<TeacherDtoResponse>();
  const [userInfo, setUserInfo] = useState<UserDtoResponse>();
  const { data, handlers } = useHR();
  const [teacherDetails, setTeacherDetails] = useState<TeacherPublicTeacherDetailDtoResponse>();
  const [image, setImage] = useState('');
  const [form] = useForm();
  const [isLoading, setLoading] = useState(false);
  const [isDarkMode] = useTheme();
  const [kpi, setKpi] = useState<number>(0);
  const [settings, setSettings] = useState<KpiApplicationSettingsDtoResponse[]>([]);
  const [kpiAppications, setKpiApplications] = useState<TeacherKpiApplicationWithKPIDto>();
  const additionalInfoDtoRequest: TeacherAdditionalInfoDtoRequest[] = [];

  const { dictionary } = useDictionary({
    fetchAcademicStatus: true,
    fetchScientificDegree: true,
    fetchCountries: true,
  });

  useEffect(() => {
    UserService.getUserInfo(id)
      .then((res) => {
        setUserInfo(res.data);
      })
    TeacherService.getPublicTeacherDetail(id)
      .then((res) => {
        setTeacherDetails(res.data);
      })
    TeacherService.getTeacherImagePublic(id).then((res) => {
      let imageUrl = URL.createObjectURL(res.data);
      setImage(imageUrl);
    });
    setLoading(true);
    if (grantPermission('HR')) {
      TeacherService.getTeacherInfoById(id).then((res) => {
        form.setFieldsValue({
          positionId: res.data.position.id,
          scientificDegreeId: res.data.scientificDegree?.id,
          academicStatusId: res.data.academicStatus?.id,
          ftutor: res.data.ftutor
        });
        setTeacherInfo(res.data);
        KpiService.getApplicationSettings()
          .then(({ data: settingData }) => {
            setSettings(settingData)
            KpiService.getTeacherApplicationsWithKpiForHR(res.data.id, settingData.find(v => v.isCurrent === true)?.id)
              .then(({ data }) => setKpiApplications(data));
            KpiService.getTeacherKpi(res.data.id, settingData.find(v => v.isCurrent === true)?.id)
              .then(({ data }) => {
                setKpi(data)
              });
          })

        setLoading(false);
      });
    } else {
      TeacherService.getTeacherPublicInfoById(id).then((res) => {
        setTeacherInfo(res.data);
        KpiService.getApplicationSettings()
          .then(({ data: settingData }) => {
            setSettings(settingData)
            KpiService.getTeacherKpi(res.data.id, settingData.find(v => v.isCurrent === true)?.id)
              .then(({ data }) => {
                setKpi(data)
              });
          })
        setLoading(false);
      });
    }
  }, [data.refresher, form, id]);

  const handleDownloadResume = () => {
    TeacherService.getTeacherResumeById(id).then(res => {
      notification.success({ message: "Resume downloaded!" })
    })
  };

  const handleSelect = (settingId: number) => {
    if (grantPermission('HR')) {
      if (teacherInfo) {
        KpiService.getTeacherApplicationsWithKpiForHR(teacherInfo?.id, settingId)
          .then(({ data }) => setKpiApplications(data));
        KpiService.getTeacherKpi(teacherInfo?.id, settingId)
          .then(({ data }) => {
            setKpi(data)
          })
      }
    }
    else {
      if (teacherInfo) {
        KpiService.getTeacherKpi(teacherInfo?.id, settingId)
          .then(({ data }) => {
            setKpi(data)
          })
      }
    }
  }

  const tabItems = [
    {
      label: 'Education',
      key: 'item-1',
      children: <PublicTeacherEducationTable dataSource={teacherDetails?.educationalInformationDtoResponse} />,
    },
    {
      label: 'Academic degree/Academic title',
      key: 'item-2',
      children: <PublicTeacherAcademicTitleTable dataSource={teacherDetails?.teacherDegreeDtoResponse} />
    },
    {
      label: 'Scientific information',
      key: 'item-3',
      children: (
        <PublicTeacherScholarInformationTable dataSource={teacherDetails?.scholarInformationDtoResponse} />
      ),
    },
    {
      label: 'Articles',
      key: 'item-4',
      children: <PublicTeacherArticlesTable datasource={teacherDetails?.articleDto} />,
    },
    {
      label: 'Scientific projects',
      key: 'item-5',
      children: <PublicTeacherScientificProjectsTable dataSource={teacherDetails?.scientificProjectDto} />,
    },
    {
      label: 'Certificates',
      key: 'item-6',
      children: <PublicTeacherCertificatesTable dataSource={teacherDetails?.certificateResponseDto} />,
    },
    {
      label: 'Work experience',
      key: 'item-7',
      children: <PublicTeacherWorkExpTable dataSource={teacherDetails?.teacherWorkExperienceDto} />,
    },
    {
      label: 'Developments',
      key: 'item-8',
      children: <PublicTeacherDevelopmentTable dataSource={teacherDetails?.teacherDevelopmentDto} />,
    },
    {
      label: 'Security documents',
      key: 'item-9',
      children: <PublicTeacherLegalDocsTable dataSource={teacherDetails?.teacherLegalDocumentDtoResponses} />,
    },
    grantPermission('HR') && {
      label: 'Orders',
      key: 'item-10',
      children:
        <TeacherOrderTable />,
    },
    {
      label: 'Trainings',
      key: 'item-11',
      children:
        <TeacherTrainingTable dataSource={teacherDetails?.teacherTrainingDirectionDtoResponse} />,
    },
    grantPermission('HR') && {
      label: 'IC information',
      key: 'item-12',
      children:
        <TeacherICTable />,
    }
  ];

  if (!teacherInfo) {
    return <Spinner size={'large'} />;
  }

  return isLoading ? (
    <Spin />
  ) : (
    <PageWrapper>
      {data.isEditMode ? (
        <Form form={form} onFinish={(teacher) => handlers.handleUpdateTeacher(id, teacher, additionalInfoDtoRequest)}>
          <Row justify="start" gutter={[20, 20]}>
            <Col flex="1 0 20%">
              <Space style={{ width: '100%', justifyContent: 'center' }}>
                <Avatar src={image} icon={<UserOutlined />} size={300} shape={'circle'} />
              </Space>
            </Col>

            <Col flex="1 0 70%">
              <Space direction={'horizontal'} style={{ width: '100%', display: 'flex' }}>
                <FormItem name={'surnameKz'}>
                  <Input placeholder="Введите фамилию" defaultValue={teacherInfo.surnameKz} />
                </FormItem>

                <FormItem name={'nameKz'}>
                  <Input placeholder="Введите имя" defaultValue={teacherInfo.nameKz} />
                </FormItem>

                <FormItem name={'patronymicKz'}>
                  <Input placeholder="Введите отчество" defaultValue={teacherInfo.patronymicKz} />
                </FormItem>
              </Space>

              <Space direction={'horizontal'} style={{ width: '100%', display: 'flex' }}>
                <FormItem name={'surnameEn'}>
                  <Input placeholder="Введите фамилию транслитом" defaultValue={teacherInfo.surnameEn} />
                </FormItem>

                <FormItem name={'nameEn'}>
                  <Input placeholder="Введите имя транслитом" defaultValue={teacherInfo.nameEn} />
                </FormItem>
              </Space>

              <FormItem name={'positionId'}>
                <Select style={{ width: '100%' }} placeholder={'Выберите должность'}>
                  {data.positionsList?.map((position) => (
                    <Select.Option key={position.id} value={position.id}>
                      {`${position.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem name={'typeId'}>
                <Select style={{ width: '100%' }} placeholder={'Выберите тип'} defaultValue={teacherInfo.type.id}>
                  {data.teacherTypeList?.map((type) => (
                    <Select.Option key={type.id} value={type.id}>
                      {`${type.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem name={'statusId'}>
                <Select style={{ width: '100%' }} placeholder={'Выберите статус'} defaultValue={teacherInfo.status?.id}>
                  {data.teacherStatusList?.map((status) => (
                    <Select.Option key={status.id} value={status.id}>
                      {`${status.name}`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                label="Ученая степень"
                name="scientificDegreeId"
                rules={[{ required: true, message: 'Выберите ученую степень' }]}
                style={{ marginBottom: '10px' }}
              >
                <Select placeholder="Выберите ученую степень" allowClear>
                  {dictionary.scientificDegrees?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {`${option.nameRu}/${option.nameKz}/${option.nameEn}`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem
                label="Академический статус"
                name="academicStatusId"
                rules={[{ required: true, message: 'Выберите академический статус' }]}
                style={{ marginBottom: '10px' }}
              >
                <Select placeholder="Выберите академический статус" allowClear>
                  {dictionary.academicStatuses?.map((option) => (
                    <Select.Option key={option.id} value={option.id}>
                      {`${option.nameRu}/${option.nameKz}/${option.nameEn}`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem name={'departmentId'}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={'Выберите департамент'}
                  defaultValue={teacherInfo.department?.id}
                >
                  {data.deps?.map((department) => (
                    <Select.Option key={department.id} value={department.id}>
                      {`${department.titleRu}`}
                    </Select.Option>
                  ))}
                </Select>
              </FormItem>

              <FormItem name={'ftutor'} label="Зарубежный учитель">
                <Select placeholder={'Выберите зарубежный тип'}>
                  <Select.Option value={true} key={0}>
                    Да
                  </Select.Option>
                  <Select.Option value={false} key={1}>
                    Нет
                  </Select.Option>
                </Select>
              </FormItem>

              <>
                <FormItem label={"Наименование организации зарубежного ученого"} style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={"Напишите наименование организации зарубежного ученого"}
                    defaultValue={teacherInfo.additionalInfoDto?.find(v => v.type.name === "F_UNIVERSITY")?.value}
                    onChange={e => additionalInfoDtoRequest[0] = { type: "F_UNIVERSITY", value: e.target.value }}
                  />
                </FormItem>

                <FormItem label={"Страна расположения вуза зарубежного ученого"} style={{ marginBottom: '10px' }}>
                  <Select
                    placeholder={"Выберите страну расположения вуза"}
                    defaultValue={
                      dictionary.countries?.find(
                        country => country.id === Number(teacherInfo.additionalInfoDto?.find(v => v.type.name === "F_UNIVERSITY_COUNTRY")?.value)
                      )?.nameRu
                    }
                    onSelect={(e) => additionalInfoDtoRequest[1] = { type: "F_UNIVERSITY_COUNTRY", value: e }}
                  >
                    {dictionary.countries?.map((data) => (
                      <Select.Option key={data.id} value={data.id}>
                        {`${data.nameRu}`}
                      </Select.Option>
                    ))}
                  </Select>
                </FormItem>

                <FormItem label={"Специальность"} style={{ marginBottom: '10px' }}>
                  <Input
                    placeholder={"Напишите специальность"}
                    defaultValue={teacherInfo.additionalInfoDto?.find(v => v.type.name === "SPECIALITY")?.value}
                    onChange={e => additionalInfoDtoRequest[2] = { type: "SPECIALITY", value: e.target.value }}
                  />
                </FormItem>

                <Space style={{ marginBottom: '10px' }}>
                  <FormItem label={"Дата начала пребывания"}>
                    <DatePicker
                      format="YYYY-MM-DD"
                      placeholder='Выберите дату'
                      defaultValue={teacherInfo.additionalInfoDto?.find(v => v.type.name === "F_START_DATE")?.value ? moment(teacherInfo.additionalInfoDto?.find(v => v.type.name === "F_START_DATE")?.value) : undefined}
                      onChange={(value, dateString) => additionalInfoDtoRequest[3] = { type: "F_START_DATE", value: dateString }}
                    />
                  </FormItem>
                  <FormItem label={"Дата окончания пребывания"}>
                    <DatePicker
                      format="YYYY-MM-DD"
                      placeholder='Выберите дату'
                      defaultValue={teacherInfo.additionalInfoDto?.find(v => v.type.name === "F_END_DATE")?.value ? moment(teacherInfo.additionalInfoDto?.find(v => v.type.name === "F_END_DATE")?.value) : undefined}
                      onChange={(value, dateString) => additionalInfoDtoRequest[4] = { type: "F_END_DATE", value: dateString }}
                    />
                  </FormItem>
                </Space>
              </>
              <Divider />

              <Title level={4}>Личные данные</Title>

              <Space direction={'vertical'}>
                <FormItem name={'birthDate'}>
                  <DatePicker
                    defaultValue={(teacherInfo?.birthDate && moment(teacherInfo.birthDate, 'YYYY-MM-DD')) || ''}
                    picker={'date'}
                    onChange={handlers.onChange}
                    size={'large'}
                    style={{ width: '100%', borderRadius: '10px' }}
                  />
                </FormItem>
                <FormItem name={'iin'}>
                  <Input placeholder="ИИН" defaultValue={teacherInfo?.iin} />
                </FormItem>
              </Space>

              <Divider />

              <Title level={5}>Контактные данные</Title>

              <Space align={'start'} direction={'vertical'}>
                <Tag color={'green'} icon={<MailOutlined />}>
                  Почта: <strong>{userInfo?.username}</strong>
                </Tag>
                <Form.Item name={'phone'}>
                  <InputMask
                    mask={'+7 (999) 999-99-99'}
                    autoComplete={'off'}
                    defaultValue={teacherInfo.phone}
                    style={
                      isDarkMode
                        ? {
                          backgroundColor: 'black',
                          width: '100%',
                          borderRadius: '10px',
                          padding: '6.5px 11px 6.5px',
                          border: '1px solid #434343',
                        }
                        : {
                          width: '100%',
                          borderRadius: '10px',
                          padding: '6.5px 11px 6.5px',
                          border: '1px solid #434343',
                        }
                    }
                  ></InputMask>
                </Form.Item>
              </Space>

              <Divider />
              {/*@ts-ignore*/}
              <Tabs defaultActiveKey="1" type="card" size={'large'} items={tabItems} />
            </Col>
            <Col flex="1 0 10%">
              <Space>
                <Button type={'primary'} htmlType={'submit'}>
                  Save
                </Button>
                <Button type={'text'} onClick={handlers.handleEditMode}>
                  Cancel
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      ) : (
        <>
          <Link to={isPublic ? "/public-teachers-list" : "/teachers"}>
            <Button type="default" icon={<LeftOutlined />}>
              Назад
            </Button>
          </Link>
          <Row justify="start" gutter={[20, 20]}>
            <Col flex="1 0 20%">
              <Space style={{ width: '100%', justifyContent: 'center' }}>
                <Avatar src={image} icon={<UserOutlined />} size={300} shape={'circle'} />
              </Space>
            </Col>
            <Col flex="1 0 70%">
              <Title level={1}>
                {teacherInfo.surnameKz} {teacherInfo.nameKz} {teacherInfo.patronymicKz}
              </Title>
              <Title level={3}>
                {teacherInfo.surnameEn} {teacherInfo.nameEn} {teacherInfo.patronymicEn}
              </Title>
              <Title level={5}>
                {teacherInfo.position.name}
                <AuthenticatedContent role={'OFFICE'} type={'baseRole'}>
                  <AuthenticatedContent role="HR" type={'addRole'}>
                    <Tag style={{ marginLeft: 5 }}>{teacherInfo?.status?.name}</Tag>
                  </AuthenticatedContent>
                </AuthenticatedContent>
              </Title>
              <AuthenticatedContent role={'OFFICE'} type={'baseRole'}>
                <AuthenticatedContent role="HR" type={'addRole'}>
                  <Title level={5}>{teacherInfo?.type?.name}</Title>
                </AuthenticatedContent>
              </AuthenticatedContent>
              <Title level={4} type={'secondary'}>
                {`${teacherInfo.department?.titleEn ?? 'No department'}`}
              </Title>
              <Divider />

              <Space direction="horizontal" size={20}>
                <div>
                  <Paragraph strong style={{ margin: 0 }}>
                    Ученая степень
                  </Paragraph>
                  <Text>{teacherInfo?.scientificDegree?.nameRu}</Text>
                </div>
                <div>
                  <Paragraph strong style={{ margin: 0 }}>
                    Академический
                    статус
                  </Paragraph>
                  <Text>{teacherInfo?.academicStatus?.nameRu}</Text>
                </div>
              </Space>

              <AuthenticatedContent role={'OFFICE'} type={'baseRole'}>
                <AuthenticatedContent role="HR" type={'addRole'}>
                  <Divider />

                  <Title level={4}>Личные данные</Title>

                  <Space direction={'vertical'}>
                    <Title level={5}>{`Дата рождения : ${teacherInfo?.birthDate}`}</Title>
                    <Title level={5}>ИИН : {teacherInfo.iin}</Title>
                  </Space>
                </AuthenticatedContent>
              </AuthenticatedContent>

              <Divider />

              <Title level={4}>Контактные данные</Title>

              <Space align={'end'} direction={'horizontal'}>
                <Tag color={'green'} icon={<MailOutlined />}>
                  Почта: <strong>{userInfo?.username}</strong>
                </Tag>
                <AuthenticatedContent role={'OFFICE'} type={'baseRole'}>
                  <AuthenticatedContent role="HR" type={'addRole'}>
                    <Tag color={'blue'} icon={<PhoneOutlined />}>
                      Телефон: <strong>{teacherInfo.phone}</strong>
                    </Tag>
                  </AuthenticatedContent>
                </AuthenticatedContent>
              </Space>

              {kpi > 0 && (
                <>
                  <Divider />
                  <Title level={4}>KPI</Title>
                  <Select placeholder="Select Year" onSelect={(e: any) => handleSelect(e)} defaultValue={settings.find(v => v.isCurrent === true)?.id} style={{ width: 200 }}>
                    {settings.map((setting, key) => (
                      <Select.Option key={key} value={setting.id} style={{ background: setting.isCurrent ? "green" : "none" }}>
                        {getFullDate(String(setting.startDate))} - {getFullDate(String(setting.endDate))}
                      </Select.Option>
                    ))}
                  </Select>
                  <Space direction="vertical" style={{ width: "60%" }}>
                    <Space style={{ alignItems: "center", justifyContent: "center", width: "100%" }}>
                      <Progress type="circle" percent={kpi * 100} />
                    </Space>
                    {grantPermission('HR') && (
                      <Bar data={{
                        labels: kpiAppications?.kpiApplicationDtoResponses.map((kpiAppication) => kpiAppication.kpiCategory.name),
                        datasets: [
                          {
                            label: "Weight",
                            data: kpiAppications?.kpiApplicationDtoResponses.map((kpiAppication) => kpiAppication.kpiCategory.weight),
                            backgroundColor: 'rgba(173,255,47, 0.6)',
                          },
                        ],
                      }} options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false
                          }
                        },
                      }} />
                    )}
                  </Space>
                </>
              )}


              <Divider />

              <Paragraph strong> Scientific interests </Paragraph>
              {teacherInfo?.scientificInterests?.map((interest, i) => (
                <Tag key={i}>{interest}</Tag>
              ))}
              <Paragraph strong style={{ marginTop: "20px" }}> Teaching disciplines </Paragraph>
              {teacherInfo?.taughtCourses?.map((course, i) => (
                <Tag key={i}>{course}</Tag>
              ))}

              <Divider />

              {!isPublic && (
                <Collapse defaultActiveKey={['1']} >
                  <CollapsePanel header={<Title level={5} >Schedule</Title>} key="schedule">
                    <ScheduleForTeacherPage tutorEmail={userInfo?.username} />
                  </CollapsePanel>
                </Collapse>
              )}


              <Divider />

              <AuthenticatedContent role={'OFFICE'} type={'baseRole'}>
                <AuthenticatedContent role="HR" type={'addRole'}>
                  {teacherInfo.resumeId && (
                    <>
                      <Button icon={<DownloadOutlined />} onClick={handleDownloadResume}>
                        Download resume
                      </Button>

                      <Divider />
                    </>
                  )}
                </AuthenticatedContent>
              </AuthenticatedContent>

              {/*@ts-ignore*/}
              <Tabs defaultActiveKey="1" type="card" size={'large'} items={tabItems} />
            </Col>
            <AuthenticatedContent role={'OFFICE'} type={'baseRole'}>
              <AuthenticatedContent role="HR" type={'addRole'}>
                <Col flex="1 0 10%">
                  <Button type={'primary'} onClick={handlers.handleEditMode}>
                    Edit
                  </Button>
                </Col>
              </AuthenticatedContent>
            </AuthenticatedContent>
          </Row>
        </>
      )}
    </PageWrapper>
  );
}

export default TeacherPage;
